import axios from '@/plugins/axios'

export default {
  namespaced: true,
  state() {
    return {
      categories: [],
      purchasedCategories: [],
      purchasedItems: [],
      purchases: [],
      history: []
    }
  },
  mutations: {
    setPurchases(state, payload) {
      state.categories = payload.categories
      state.purchasedCategories = payload.top_line_purchased_categories

      state.purchases = payload.data
    },
    setHistory(state, payload) {
      state.history = payload
    },
    setPurchasedItems(state, payload) {
      state.purchasedItems = payload
    },
    clearPurchases(state) {
      for (const key in state) {
        state[key] = []
      }
    }
  },
  actions: {
    async getPurchases({ commit }) {
      const response = await axios.get('/api/admin/companies/paid-services/list')

      commit('setPurchases', response.data)
    },
    async getHistory({ commit }, companyId) {
      const response = await axios.get(`/api/admin/companies/paid-services/${companyId}/history`)

      commit('setHistory', response.data)
    },
    async getPurchasedItems({ commit }, companyId) {
      const response = await axios.get(`/api/admin/companies/paid-services/${companyId}/get`)

      commit('setPurchasedItems', response.data)
    }
  },
  getters: {
    getPurchasedCategories: (state) => {
      return state.purchasedCategories.map((purchase) => {
        return {
          categoryIds: purchase.categories.map(category => category.id),
          companyId: purchase.company.id,
          companyName: purchase.company.name,
          expirationAt: purchase.expiration_at 
        }
      })
    }
  }
}
