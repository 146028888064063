export default function({ store, to, next }) {
  if (store.state.auth.user?.role !== 'user') {
    return next({
      name: 'Error',
      params: { pathMatch: to.path.substring(1).split('/') },
      query: to.query,
      hash: to.hash
    })
  }

  return next()
}
