import _ from 'lodash'

import { isValid, isBefore, parseISO } from 'date-fns'

import { defineRule, configure } from 'vee-validate'
import {
  required,
  email,
  mimes,
  min,
  max,
  min_value, // eslint-disable-line
  max_value // eslint-disable-line
} from '@vee-validate/rules'

import { localize, setLocale } from '@vee-validate/i18n'
import ru from '@vee-validate/i18n/dist/locale/ru.json'

defineRule('required', required)
defineRule('email', email)
defineRule('mimes', mimes)

defineRule('min', min)
defineRule('max', max)

defineRule('min_value', min_value)
defineRule('max_value', max_value)

defineRule('select', (value) => {
  if (_.isEmpty(value)) {
    return false
  }

  return true
})

defineRule('required_with', (value, fields, ctx) => {
  let required = false
  
  if (fields.length > 0) {
    required = fields.some((field) => {
      const val = _.get(ctx.form, field)
      
      return !value?.trim() && val?.trim()
    })

    return !required
  }

  return required
})

defineRule('alpha_itn', (value) => {
  if (!Number.isNaN(Number(value)) && (value.length < 10 || value.length > 12)) {
    return 'Укажите корректный ИНН'
  }
  
  return true
})

defineRule('date', (value, [date]) => {
  if (!value) {
    return true
  }
  
  const parseValue = parseISO(value.split('.').reverse().join('-'))
  const parseDate = parseISO(date)
  
  if (!isValid(parseDate)) {
    return isBefore(parseValue, new Date().setHours(0, 0, 0))
  }
  
  return isBefore(parseValue, parseDate)
})

defineRule('size', (files, [size]) => {
  if (files === null || files === undefined) {
    return false
  }

  const nSize = size * 1e+6

  if (!Array.isArray(files)) {
    return files.size <= nSize
  }

  for (let i = 0; i < files.length; i++) {
    if (files[i].size > nSize) {
      return false
    }
  }

  return true
})

defineRule('domain', (value) => {
  return /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9].[a-zA-Z]{2,}$/.test(value)
})

defineRule('phone', (value) => {
  return /^(\+?([0-9]{6,14}?))?$/i.test(value)
})

defineRule('telegram', (value) => {
  return /^(\+?([a-zA-Z0-9_]{5,32}?))?$/i.test(value)
})

localize({ ru })

configure({
  generateMessage: localize('ru', {
    fields: {
      poster: {
        required: 'Загрузите постер'
      },
      delivery_certificate: {
        required: 'Загрузите файл'
      },
      preview: {
        required: 'Загрузите превью'
      },
      technologies: {
        select: 'Укажите технологии'
      },
      date_birth: {
        date: 'Возраст не может быть меньше 18 лет'
      }
    },
    messages: {
      required: 'Заполните поле',
      required_with: 'Заполните поле',
      select: 'Выберите значение',
      email: 'Введите корректный адрес',
      mimes: 'Недопустимый тип файла',
      size: 'Размер файла должен быть меньше 0:{size} MB',
      min: '{field} не может быть короче 0:{length} символов',
      max: '{field} не может быть длинее 0:{length} символов',
      min_value: 'Число должно быть 0:{length} или больше',
      max_value: 'Число должно быть 0:{length} или меньше',
      date: 'Введите действительную дату',
      domain: 'Укажите корректное доменное имя',
      phone: 'Укажите корректный номер телефона',
      telegram: 'Укажите корректное имя или номер телефона'
    }
  }),
  validateOnBlur: false,
  validateOnChange: false,
  validateOnModelUpdate: false
})

setLocale('ru')
