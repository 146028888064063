export default {
  namespaced: true,
  state() {
    return {
      data: {}
    }
  },
  mutations: {
    setModalData(state, payload) {
      state.data = payload
    },
    clearModalData(state) {
      state.data = {}
    }
  }
}
