import Cookies from 'js-cookie'

import axios from '@/plugins/axios'

export default {
  namespaced: true,
  state() {
    return {
      loggedIn: false,
      user: null
    }
  },
  mutations: {
    setUser(state, user) {
      state.loggedIn = !!user
      state.user = user
    }
  },
  actions: {
    async fetchUser({ commit }) {
      const response = await axios.get('/api/admin/me')

      commit('setUser', response.data)
    },
    async logout() {
      try {
        await axios.post('/api/admin/logout')

        Cookies.remove('auth._token.local', { domain: process.env.VUE_APP_COOKIE_DOMAIN })
        Cookies.remove('auth._token_expiration.local', { domain: process.env.VUE_APP_COOKIE_DOMAIN })

        window.location.href = process.env.VUE_APP_SITE_URL
      } catch (error) {
        console.error(error)
      }
    }
  }
}
