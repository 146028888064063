import Cookies from 'js-cookie'

import { createApp } from 'vue'

import VueAxios from 'vue-axios'
import axios from './plugins/axios'

import router from './router'
import store from './store'

import emitter from 'tiny-emitter/instance'

import vuescroll from 'vuescroll'

import './plugins/vee-validate'

import VTooltipPlugin from 'v-tooltip'
import 'v-tooltip/dist/v-tooltip.css'

import CKEditor from '@ckeditor/ckeditor5-vue'

import { createMetaManager } from 'vue-meta'

import Notifications from '@kyvg/vue3-notification'

import App from './App.vue'

import './assets/styles/main.scss'

const app = createApp(App)

app.use(store)

app.use(createMetaManager())

app.use(vuescroll)

app.use(VueAxios, axios)
app.provide('axios', app.config.globalProperties.axios)

app.config.globalProperties.emitter = emitter

VTooltipPlugin.options.offset = [0, 7]

VTooltipPlugin.options.popperOptions = {
  modifiers: {
    flip: {
      enabled: false
    }
  }
}

VTooltipPlugin.options.themes.menu.delay = { show: 200, hide: 0 }

VTooltipPlugin.options.themes.info = {
  $extend: 'dropdown',
  triggers: ['hover', 'focus'],
  popperTriggers: ['hover', 'focus'],
  delay: {
    show: 200,
    hide: 0
  }
}

app.use(VTooltipPlugin)

app.use(CKEditor)

app.use(Notifications)

const checkAuth = async () => {
  if (process.env.NODE_ENV === 'production') {
    Cookies.remove('auth._token.local', { path: '/' })
    Cookies.remove('auth._token_expiration.local', { path: '/' })
  }
  
  try {
    await store.dispatch('auth/fetchUser')

    app.use(router(app)).mount('#app')
  } catch (error) {
    console.error(error)
  }
}

checkAuth()
