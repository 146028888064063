import { format } from 'date-fns'

import ru from 'date-fns/locale/ru'

export function useValue(value, locale = 'ru-RU') {
  return Number.isInteger(value)
    ? Intl.NumberFormat(locale).format(value)
    : value
}

export function useDate(value, dateFormat = null) {
  if (!value) {
    return '-'
  }

  const date = new Date(value)

  return date instanceof Date && !isNaN(date)
    ? dateFormat
    ? format(date, dateFormat, { locale: ru })
    : format(date, 'dd.MM.yyyy', { locale: ru })
    : '-'
}

export function useNoun(quantity, one, two, five, noun = false) {
  if ((!quantity && quantity !== 0) || !one || !two || !five) {
    return '-'
  }

  let n = Math.abs(quantity)

  n %= 100

  if (n >= 5 && n <= 20) {
    return noun ? five : `${quantity} ${five}`
  }

  n %= 10

  if (n === 1) {
    return noun ? one : `${quantity} ${one}`
  } else if (n >= 2 && n <= 4) {
    return noun ? two : `${quantity} ${two}`
  }

  return noun ? five : `${quantity} ${five}`
}

export function useImage(image, name) {
  const images = {
    avatar: require('@/assets/images/avatar.svg'),
    company: require('@/assets/images/company.svg')
  }
  
  return image || images[name]
}

export function useShortName(person) {
  return person.name && person.last_name ? `${person.name} ${person.last_name}` : person.name
}

export function useReplace(value, regexp) {
  return value.replace(regexp, '')
}

export function useScrollToError(selector = '.error', parent = true) {
  const element = parent
    ? document.querySelector(selector)?.closest('.form-group') 
    : document.querySelector(selector)
    
  if (element) {
    const header = document.querySelector('.header')
    const content = document.querySelector('.content')
    
    const activeArea = window.scrollY || !content ? window : content
    
    const scrollY = activeArea?.scrollY || (activeArea?.scrollTop - header?.offsetHeight) || 0
    
    activeArea.scrollTo({
      top: (element.getBoundingClientRect().top + scrollY) - 15,
      behavior: 'smooth'
    })
  }
}
