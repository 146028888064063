import Cookies from 'js-cookie'

import axios from 'axios'

const config = {
  baseURL: process.env.VUE_APP_API_URL
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true // Check cross-site Access-Control
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  function(config) {
    const token = Cookies.get('auth._token.local')
    
    if (/^Bearer/i.test(token)) {
      config.headers.authorization = token
    }

    return config
  },
  function(error) {
    // Делаем что-то с ошибкой запроса
    return Promise.reject(error)
  }
)

_axios.interceptors.response.use(
  function(response) {
    // Делаем что-то с ответными данными
    return response
  },
  function(error) {
    if (error.response?.status === 401) {
      window.location.href = `${process.env.VUE_APP_SITE_URL}/login`
    }

    return Promise.reject(error)
  }
)

export default _axios
