<template>
  <div class="layout">
    <template v-if="loggedIn">
      <transition name="aside">
        <Navigation v-show="isOpen"/>
      </transition>
    </template>
    <main 
      :class="['main', {
        'main--logged-in': loggedIn
      }]"
    >
      <Header
        v-if="loggedIn"
        :is-open="isOpen"
        @toggle="isOpen = !isOpen"
      />
      <div 
        :class="['content', {
          'content--error': isPageError
        }]"
      >
        <slot :key="getSlotKey"/>
      </div>
      <transition name="fade">
        <Confirm
          v-if="confirm.isVisible"
          :text="confirm.options.text"
          @cancel="cancelAction"
          @confirm="confirmAction"
        />
      </transition>
    </main>
    <transition name="fade-backdrop">
      <div
        v-if="isOpen"
        class="backdrop"
        @click="isOpen = false"
      />
    </transition>
  </div>
</template>

<script setup>
  import { reactive, ref, computed, onMounted, onUnmounted, watch } from 'vue'
  
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'

  import { useNotification } from '@kyvg/vue3-notification'
  import { useEmitter } from '@/composables/emitter'

  import Navigation from '@/components/Navigation'
  import Header from '@/components/Header'

  import Confirm from '@/components/sections/Confirm'

  const route = useRoute()
  const store = useStore()

  const { notify } = useNotification()

  const emitter = useEmitter()
  
  const confirm = reactive({
    isVisible: false,
    options: {}
  })
  
  const isOpen = ref(false)

  onMounted(() => {
    emitter.on('confirm', setConfirm)
  })

  onUnmounted(() => {
    emitter.off('confirm', setConfirm)
  })

  watch(route, () => {
    isOpen.value = false
  })

  const loggedIn = computed(() => {
    return store.state.auth.loggedIn
  })

  const isPageError = computed(() => {
    return route.name === 'Error'
  })
  
  const getSlotKey = computed(() => {
    return route.path.substring(1).split('/')[0]
  })

  async function confirmAction() {
    confirm.isVisible = false

    try {
      await confirm.options.callback()
    } catch (error) {
      notify({
        type: 'error',
        text: 'Произошла ошибка, попробуйте снова!'
      })

      console.error(error)
    }
  }
  
  function setConfirm(payload) {
    confirm.options = payload

    confirm.isVisible = true
  }

  function cancelAction() {
    confirm.isVisible = false

    confirm.options = {}
  }
</script>
