import axios from '@/plugins/axios'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {
    setCompany(state, payload) {
      Object.assign(state, payload)
    },
    clearCompany(state) {
      for (const key in state) {
        delete state[key]
      }
    }
  },
  actions: {
    async getCompany({ commit }, companyId) {
      const { data: response } = await axios.get(`/api/admin/companies/${companyId}/profile`)

      commit('setCompany', response.data)
    }
  }
}
