import { createStore } from 'vuex'

import auth from './modules/auth'
import company from './modules/company'
import data from './modules/data'
import modal from './modules/modal'
import purchases from './modules/purchases'

export default createStore({
  state: {},
  mutations: {},
  modules: {
    auth,
    company,
    data,
    modal,
    purchases
  }
})
