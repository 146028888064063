<template>
  <div class="confirm-change confirm-change--default">
    <div class="container">
      <div class="confirm-change__content confirm-change-content">
        <p class="confirm-change-content__text">{{ props.text }}</p>
        <div class="confirm-change-content__actions">
          <button
            type="button"
            class="btn btn--error confirm-change-content__button"
            @click="$emit('cancel')"
          >
            {{ getButtonText[0] }}
          </button>
          <button
            type="button"
            class="btn btn--success confirm-change-content__button"
            @click="$emit('confirm')"
          >
            {{ getButtonText[1] }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue'

  const props = defineProps({
    type: {
      type: String,
      default: 'confirm',
      validator: (value) => {
        return ['confirm', 'save'].includes(value)
      }
    },
    text: {
      type: String,
      required: true
    }
  })

  defineEmits([
    'cancel',
    'confirm'
  ])

  const getButtonText = computed(() => {
    return props.type === 'confirm'
      ? ['Отменить', 'Подтвердить действие']
      : ['Не сохранять', 'Сохранить изменения']
  })
</script>
