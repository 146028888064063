import axios from '@/plugins/axios'

export default {
  namespaced: true,
  state() {
    return {
      categories: [],
      cities: [],
      competences: [],
      countries: [],
      specialities: [],
      technologies: [],
      groups: [],
      filters: []
    }
  },
  mutations: {
    setData(state, payload) {
      state[payload.type] = payload.data
    }
  },
  actions: {
    async getData({ state, commit, getters }, type) {
      if (state[type].length > 0) {
        return
      }

      const { data: response } = await axios.get(getters.getRequest(type))

      commit('setData', {
        type,
        data: response.data ?? response
      })
    }
  },
  getters: {
    getRequest: state => (type) => {
      switch (type) {
        case 'categories':
          return '/api/categories'
        case 'cities':
          return '/api/admin/companies/cities'
        case 'competences':
          return '/api/admin/companies/competences'
        case 'countries':
          return '/api/admin/companies/countries'
        case 'specialities':
          return '/api/admin/companies/specialities'
        case 'technologies':
          return '/api/admin/technologies/list'
        case 'groups':
          return '/api/specialists/groups'
        case 'filters':
          return '/api/rating-filters'
        default:
          return ''
      }
    },
    getFilterSelectors: state => {
      const types = state.filters.map(rating => rating.rating_filter_types).flat()
      
      return types.filter((type) => {
        return type.select_method === 'select' || type.select_method === 'multiselect'
      })
    }
  }
}
