<template>
  <aside class="aside">
    <div class="aside-content">
      <div class="aside-content__top">
        <div 
          v-if="company"
          class="aside-company"
        >
          <a 
            :href="`${siteUrl}/company-${company.website_slug}`" 
            class="aside-company__link" 
            target="_blank"
          >
            <img
              :src="useImage(company.logo, 'company')"
              class="aside-company__logotip"
              alt="#"
            >
            <p class="aside-company__name">{{ company.name }}</p>
          </a>
        </div>
        <div class="aside-menu">
          <RouterLink
            v-for="(link, index) in getNavigation(navigation.user)"
            :key="index"
            :class="{ 'active': isActive(link.path) }"
            :to="link.path"
          >
            <img
              :src="require(`@/assets/images/navigation/${link.icon}.svg`)"
              alt="#"
            >
            <span>{{ link.name }}</span>
          </RouterLink>
        </div>
        <template v-if="isAdmin">
          <hr>
          <div class="aside-menu">
            <RouterLink
              v-for="(link, index) in getNavigation(navigation.admin)"
              :key="index"
              :class="{ 'active': isActive(link.path) }"
              :to="link.path"
            >
              <img
                v-if="link.icon"
                :src="require(`@/assets/images/navigation/${link.icon}.svg`)"
                alt="#"
              >
              <span>{{ link.name }}</span>
              <span 
                v-if="getQuantity(link.label)"
                class="quantity"
              >
                {{ `+${getQuantity(link.label)}` }}
              </span>
            </RouterLink>
          </div>
        </template>
      </div>
      <div class="aside-menu">
        <button
          type="button"
          class="btn"
          @click="logout"
        >
          <img
            src="@/assets/images/navigation/logout.svg"
            alt="#"
          >
          <span>Выход</span>
        </button>
      </div>
    </div>
  </aside>
</template>

<script setup>
  import { inject, ref, computed, watch, onMounted, onUnmounted } from 'vue'

  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'

  import { useEmitter } from '@/composables/emitter'
  import { useImage } from '@/composables/helpers'

  const axios = inject('axios')
  
  const route = useRoute()
  const store = useStore()

  const emitter = useEmitter()

  const siteUrl = process.env.VUE_APP_SITE_URL
  
  const navigation = {
    user: [
      {
        label: 'my-company',
        name: 'Моя компания',
        path: '/my-company',
        icon: 'my-company',
        excluded: {}
      },
      {
        label: 'advertising',
        name: 'Реклама',
        path: '/advertising',
        icon: 'advertising',
        excluded: {}
      },
      {
        label: 'outstaff',
        name: 'Аутстафф',
        path: '/outstaff',
        icon: 'outstaff',
        excluded: {}
      },
      {
        label: 'support',
        name: 'Тех. подержка',
        path: '/support',
        icon: 'support',
        excluded: {
          user: false,
          admin: true
        }
      }
    ],
    admin: [
      {
        label: 'companies',
        name: 'Компании',
        path: '/companies',
        icon: 'companies'
      },
      {
        label: 'feedbacks',
        name: 'Отзывы',
        path: '/reviews',
        icon: 'reviews'
      },
      {
        label: 'mailing',
        name: 'Рассылки',
        path: '/mailing',
        icon: 'mailing'
      },
      {
        label: 'subscriptions',
        name: 'Подписки на рассылку',
        path: '/subscriptions',
        icon: 'subscriptions'
      },
      {
        label: 'articles',
        name: 'Статьи',
        path: '/articles',
        icon: 'articles'
      },
      {
        label: 'projects',
        name: 'Проверка проектов',
        path: '/projects',
        icon: 'projects'
      },
      {
        label: 'paid_services',
        name: 'Покупки',
        path: '/purchases',
        icon: 'purchases'
      },
      {
        label: 'supports',
        name: 'Тех. поддержка',
        path: '/admin-support',
        icon: 'admin-support'
      }
    ]
  }
  
  const data = ref({})
  
  onMounted(() => {
    emitter.on('fetch', fetchData)
  })

  onUnmounted(() => {
    emitter.off('fetch', fetchData)
  })
  
  const company = computed(() => {
    return store.state.auth.user?.company
  })
  
  const role = computed(() => {
    return store.state.auth.user?.role
  })

  const isAdmin = computed(() => {
    return role.value === 'admin'
  })

  watch(() => route.path, async () => {
    if (isAdmin.value) {
      await fetchData()
    } 
  })
  
  function isActive(path) {
    return route.path.startsWith(path)
  }
  
  function getNavigation(array) {
    return array.filter(item => !item?.excluded?.[role.value])
  }

  function getQuantity(label) {
    for (const key in data.value) {
      if (label === key) {
        return data.value[key]
      }
    }

    return 0
  }
  
  async function fetchData() {
    try {
      const { data: response } = await axios.get('/api/admin/companies/for-moderation-data')

      data.value = response.data
    } catch (error) {
      console.error(error)
    }
  }

  async function logout() {
    await store.dispatch('auth/logout')
  }
</script>
