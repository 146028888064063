<template>
  <div class="header">
    <div class="container">
      <RouterLink
        to="/profile"
        class="user"
      >
        <img
          :src="useImage(getAvatar, 'avatar')"
          alt="#"
        >
      </RouterLink>
      <div
        :class="['menu-opener menu-open-aside', {
          'active': props.isOpen
        }]"
        @click="$emit('toggle')"
      >
        <i/>
        <i/>
        <i/>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue'
  import { useStore } from 'vuex'

  import { useImage } from '@/composables/helpers'

  const store = useStore()

  const props = defineProps({
    isOpen: {
      type: Boolean,
      default: false
    }
  })

  const getAvatar = computed(() => {
    return store.state.auth.user?.avatar || ''
  })
</script>
